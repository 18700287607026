<template>
  <div>
    <div class="wrapper">
      <div class="filters__mobile">
        <div class="filters__mobile--wrap">
          <div class="moderation__search filters--moderation--search" style="margin-right: 0">
            <input
              type="text"
              v-model="params.search"
              placeholder="Поиск (дата или ник)"
              class="moderation__search--input"
            />
            <div class="moderation__search--icon">
              <svg @click="setModerationBloggers" class="moderation__search--pic">
                <use xlink:href="../../assets/img/icons/sprite.svg#search-solid"></use>
              </svg>
            </div>
          </div>
          <div class="moderation__filters--buttons">
            <button class="btn moderation__filters--btn btn--theme--yellow">Найти</button>
            <button class="btn moderation__filters--btn btn--theme--white">Сбросить</button>
          </div>
          <div class="filters__cross">
            <span class="filters__cross--line"></span>
            <span class="filters__cross--line"></span>
          </div>
        </div>
      </div>

      <div>
        <div class="container" style="position: relative">
          <div
            class="statistics--card__title statistics--card__title--set mod__title"
            style="margin-bottom: 20px"
          >
            Модерация заявок
          </div>
          <h5 class="">Поиск и фильтр</h5>
          <div class="moderation">
            <div class="d-flex flex-row justify-content-between flex-wrap">
              <div
                class="
                  moderation__buttons
                  mt-3
                  overflow-auto
                  d-flex
                  align-items-center
                  w-50
                  position-relative
                "
                style="height: 40px"
              >
                <b-button-group class="position-relative">
                  <counter-button
                    :key="bloggers.length"
                    :counter="getNewCount"
                    class="ws-nowrap"
                    :variant="
                      Number(params.moderation_status) === 1 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(1, 'moderation_status')"
                    >Свежие</counter-button
                  >
                  <b-button
                    class="ws-nowrap"
                    :variant="
                      Number(params.moderation_status) === 4 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(4, 'moderation_status')"
                    >На доработке</b-button
                  >
                  <b-button
                    class="ws-nowrap"
                    :variant="
                      Number(params.moderation_status) === 2 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(2, 'moderation_status')"
                    >Одобренные</b-button
                  >
                  <b-button
                    class="ws-nowrap"
                    :variant="
                      Number(params.moderation_status) === 5 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(5, 'moderation_status')"
                    >Забаненные</b-button
                  >
                  <counter-button
                    :key="bloggers.length"
                    class="ws-nowrap"
                    :counter="getChangedCount"
                    :variant="
                      Number(params.moderation_status) === 6 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(6, 'moderation_status')"
                    >Измененные</counter-button
                  >
                </b-button-group>
              </div>
              <b-button-group
                class="mt-3"
                style="height: 33px"
                v-if="Number(params.moderation_status) === 2"
                ><b-button
                  :variant="!params.processed_automatically ? 'primary' : 'outline-default'"
                  @click="() => changeParam(false, 'processed_automatically')"
                  >Вручную</b-button
                ><b-button
                  :variant="params.processed_automatically ? 'primary' : 'outline-default'"
                  @click="() => changeParam(true, 'processed_automatically')"
                  >Автоматически</b-button
                ></b-button-group
              >
              <b-input-group style="height: 33.5px" class="custom-search-field ml-llg-4 mt-3">
                <b-form-input
                  v-model.lazy="params.search"
                  type="text"
                  placeholder="Поиск (дата или ник)"
                ></b-form-input>
                <b-input-group-append>
                  <b-button @click="setModerationBloggers" variant="outline-default"
                    ><b-icon icon="search"
                  /></b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="mt-4">
              <h4 class="moderation__title--small">Статистика блогеров</h4>
              <div class="d-flex flex-wrap justify-content-between">
                <ModerationCard
                  v-for="blogger in bloggers"
                  :moderationSteps="moderationSteps"
                  :key="blogger.id"
                  :blogger="blogger"
                  @update="updateModerationBlogger"
                />
              </div>
            </div>
            <pagination
              v-if="pages > 1"
              :current="params.page"
              :total="pages"
              @page-change="changeParam($event, 'page')"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ModerationCard from './ModerationCard.vue';
import Pagination from '../Pagination.vue';
import bloggers from '../../api/bloggers';
import CounterButton from '../reusable/elements/buttons/CounterButton.vue';

export default {
  data: () => ({
    params: {
      page_size: 10,
      moderation_status: 1,
      moderationSteps: [],
      page: 1,
      search: '',
      ordering: '-created_at',
    },
    bloggers: [],
    pages: 0,
  }),
  components: {
    ModerationCard,
    Pagination,
    CounterButton,
  },
  watch: {
    'params.moderation_status': {
      handler(val) {
        if (Number(val) !== 2 && this.params.processed_automatically) {
          this.changeParam(false, 'processed_automatically');
        }
      },
    },
  },
  methods: {
    async updateModerationBlogger(id, data) {
      try {
        const result = await bloggers.moderation.update(id, {
          ...data,
          mhi: data.last_moderation_history_item,
        });
      } catch (e) {
        console.log(e);
      } finally {
        console.log('FETCHING BLOGGER LIST');
        await this.setModerationBloggers();
      }
    },
    async getNewCount() {
      const response = await bloggers.moderation.fnc(
        { actions: ['count'] },
        { moderation_status: 1, filled: true }
      );
      return response.result;
    },
    async getChangedCount() {
      const response = await bloggers.moderation.fnc(
        { actions: ['count'] },
        { moderation_status: 6, filled: true }
      );
      return response.result;
    },
    async setModerationBloggers() {
      const response = await bloggers.moderation.list(this.params);
      this.pages = response.total_pages;
      this.bloggers = response.results;
    },
    async filter(status) {
      this.params.status = status;
      await this.setModerationBloggers();
    },
    async changeParam(value, param) {
      this.$set(this.params, param, value);
      await this.setModerationBloggers();
    },
  },
  async mounted() {
    const res = await bloggers.moderation.steps();
    this.moderationSteps = res.results;
    await this.setModerationBloggers();
  },
};
</script>

<style lang="scss" scoped>
.btn__wrap {
  display: flex;
}
.moderation__row:not(:last-child) {
  margin-bottom: 20px;
}
.moderation-wrap {
  border: 1px solid green !important;
}
</style>
