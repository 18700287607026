<template>
  <b-button
    :counter="count"
    class="position-relative"
    :class="{ btn___counter: count }"
    v-on="$listeners"
    v-bind="{ ...$props, ...$attrs }"
    ><slot></slot
  ></b-button>
</template>

<script>
export default {
  data: () => ({
    count: null,
  }),
  props: {
    counter: [Number, Function],
  },
  methods: {
    async getCount() {
      if (typeof this.counter === 'function') {
        try {
          const res = await this.counter();
          return res;
        } catch (e) {
          console.log(e);
          return 0;
        }
      }
      return this.counter;
    },
  },
  async mounted() {
    this.count = await this.getCount();
  },
};
</script>
<style lang="scss" scoped>
.btn___counter {
  overflow: inherit;
}
.btn___counter::before {
  content: attr(counter);
  position: absolute;
  width: 17px;
  height: 17px;
  z-index: 1;
  background-color: red;
  border-radius: 50%;
  color: #fff;
  line-height: 17px;
  font-size: 12px;
  top: -15%;
  display: table-cell;
  right: -3%;
  vertical-align: middle;
  text-align: center;
}
</style>
