<template>
  <div
    :style="forRevisionModel.length ? 'border: 1px solid red' : ''"
    class="hovering__pad d-flex flex-column justify-content-between mod-stat-card"
    style="min-height: 600px; max-width: 550px !important"
  >
    <div class="p-3">
      <div class="moderation__header">
        <b-button
          variant="link"
          target="_blank"
          size="lg"
          :href="`https://instagram.com/${blogger.instaname}`"
          class="moderation__title--blue mb-1 mb-md-0"
          >{{ blogger.instaname }}</b-button
        >
        <span class="d-inline-block mb-1 mb-md-0">{{ dateCreated }}</span>
        <div class="moderation__title--opac mb-3 mb-md-0">Шаг {{ step }} из 8. Статистика</div>
      </div>
      <template v-if="step === 1">
        <div class="settings__info--wrap">
          <h4 class="settings__title--small moderation__st">Охваты сторис</h4>

          <form inline>
            <b-form-group class="d-inline-block mr-3" label="От">
              <b-form-input v-model="blogger.coverage_min">Минимум</b-form-input>
            </b-form-group>
            <b-form-group class="d-inline-block" label="До">
              <b-form-input v-model="blogger.coverage_max">Максимум</b-form-input>
            </b-form-group>
          </form>

          <div v-if="blogger.coverage_screens && blogger.coverage_screens.length">
            <p style="margin-top: 20px" class="blue-text">Оригиналы скриншотов (кликабельно)</p>
            <template v-for="screen in blogger.coverage_screens">
              <a :key="screen.id" target="_blank" :href="screen.image.full_size">
                <img :src="screen.image.thumbnail" />
              </a>
            </template>
          </div>
          <p v-else style="margin-top: 20px" class="blue-text">
            Блогер не прикрепил скриншоты охватов сторис
          </p>
        </div>
      </template>
      <template v-if="step === 2">
        <div class="settings__info--wrap">
          <h4 class="settings__title--small moderation__st">Охваты постов</h4>
          <b-form inline>
            <b-form-group class="d-inline-block mr-3" label="От">
              <b-form-input v-model="blogger.coverage_posts_min">Минимум</b-form-input>
            </b-form-group>
            <b-form-group class="d-inline-block" label="До">
              <b-form-input v-model="blogger.coverage_posts_max">Максимум</b-form-input>
            </b-form-group>
          </b-form>

          <div v-if="blogger.posts_coverage_screens && blogger.posts_coverage_screens.length">
            <p style="margin-top: 20px" class="blue-text">Оригиналы скриншотов (кликабельно)</p>
            <template v-for="screen in blogger.posts_coverage_screens">
              <a :key="screen.id" target="_blank" :href="screen.image.full_size">
                <img :src="screen.image.thumbnail" />
              </a>
            </template>
          </div>
          <p v-else style="margin-top: 20px" class="blue-text">
            Блогер не прикрепил скриншоты охватов постов
          </p>
        </div>
      </template>
      <template v-if="step === 3">
        <div class="settings__info--wrap">
          <h4 class="settings__title--small moderation__st">Процент женщин</h4>

          <b-form inline>
            <b-form-input v-model="blogger.females_percent"></b-form-input>
          </b-form>

          <div v-if="blogger.women_percent_screens && blogger.women_percent_screens.length">
            <p style="margin-top: 20px" class="blue-text">Оригиналы скриншотов (кликабельно)</p>
            <template v-for="screen in blogger.women_percent_screens">
              <a :key="screen.id" target="_blank" :href="screen.image.full_size">
                <img :src="screen.image.thumbnail" />
              </a>
            </template>
          </div>
          <p v-else style="margin-top: 20px" class="blue-text">
            Блогер не прикрепил скриншоты процента женщин
          </p>
        </div>
      </template>
      <template v-if="step === 4">
        <div class="settings__info--wrap">
          <h4 class="settings__title--small moderation__st">Преобладающий возраст в процентах</h4>
          <b-form inline>
            <b-form-group class="mr-3">
              <Multiselect
                :value="currentAudience"
                placeholder="Возраст"
                track-by="value"
                label="display_name"
                :show-labels="false"
                @input="(val) => (blogger.main_audience.age_group = val.value)"
                :options="options.audiences.child.children.age_group.choices"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input v-model="blogger.main_audience.age_group_percent"></b-form-input>
            </b-form-group>
          </b-form>
          <div v-if="blogger.main_audience.screens && blogger.main_audience.screens.length">
            <p style="margin-top: 20px" class="blue-text">Оригиналы скриншотов (кликабельно)</p>
            <template v-for="screen in blogger.main_audience.screens">
              <a :key="screen.id" target="_blank" :href="screen.image.full_size">
                <img :src="screen.image.thumbnail" />
              </a>
            </template>
          </div>
          <p v-else style="margin-top: 20px" class="blue-text">
            Блогер не прикрепил скриншоты аудитории
          </p>
        </div>
      </template>
      <template v-if="step === 5">
        <div class="settings__info--wrap">
          <h4 class="settings__title--small moderation__st">Преобладающая страна в процентах</h4>
          <b-form inline>
            <b-form-group class="mr-3">
              <Multiselect
                class="country__select"
                v-model="blogger.main_country.country"
                placeholder="Выберите страну"
                :show-labels="false"
                :options="countries"
                track-by="id"
                label="name"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                style="width: 35%"
                v-model="blogger.main_country.coverage"
              ></b-form-input>
            </b-form-group>
          </b-form>

          <div v-if="blogger.main_country.screens && blogger.main_country.screens.length">
            <p style="margin-top: 20px" class="blue-text">Оригиналы скриншотов (кликабельно)</p>
            <template v-for="screen in blogger.main_country.screens">
              <a :key="screen.id" target="_blank" :href="screen.image.full_size">
                <img :src="screen.image.thumbnail" />
              </a>
            </template>
          </div>
          <p v-else style="margin-top: 20px" class="blue-text">
            Блогер не прикрепил скриншоты страны
          </p>
        </div>
      </template>
      <template v-if="step === 6">
        <div class="settings__info--wrap">
          <h4 class="settings__title--small moderation__st">Преобладающий город в процентах</h4>
          <b-form inline>
            <b-form-group class="mr-3">
              <city-select v-model="blogger.main_city.city" />
            </b-form-group>
            <b-form-group>
              <b-form-input v-model="blogger.main_city.coverage"></b-form-input>
            </b-form-group>
          </b-form>

          <div v-if="blogger.main_city.screens && blogger.main_city.screens.length">
            <p style="margin-top: 20px" class="blue-text">Оригиналы скриншотов (кликабельно)</p>
            <template v-for="screen in blogger.main_city.screens">
              <a :key="screen.id" target="_blank" :href="screen.image.full_size">
                <img :src="screen.image.thumbnail" />
              </a>
            </template>
          </div>
          <p v-else style="margin-top: 20px" class="blue-text">
            Блогер не прикрепил скриншоты города
          </p>
        </div>
      </template>
      <template v-if="step === 7">
        <div class="mod_content-wrapper">
          <template v-if="blogger.pr_types.length">
            <div
              v-for="type in blogger.pr_types"
              :key="`${type.id}prTpbLdhnfoy^3`"
              class="moderation__col--block"
            >
              <h5 class="d-flex flex-wrap justify-content-between justify-content-sm-start mt-sm-3">
                Формат №{{ type.id }}
                <b-button
                  variant="link"
                  @click="() => blogger.pr_types.splice(blogger.pr_types.indexOf(type), 1)"
                  class="ml-sm-3"
                  >Удалить формат</b-button
                >
              </h5>
              <div class="moderation__text">
                <div class="moderation__text--content">{{ getKind(type) }}</div>
                <div class="moderation__text--content">
                  <span class="moderation__text--opac">Кто может заказать:</span>
                  {{ type.target }}
                </div>
                <div class="moderation__text--content">
                  <span class="moderation__text--opac">Набор рекламы на:</span>
                  {{ getMonth(type) }}
                </div>
                <div class="moderation__text--content">
                  <span class="moderation__text--opac">Цена:</span> {{ type.uPrice }} ₽
                </div>
                <div class="moderation__text--opac mb-1" style="margin-top: 10px">Условия</div>
                <div class="moderation__text--content mb-1">
                  {{ type.content }}
                </div>
              </div>
              <b-button
                variant="link"
                v-if="type.content"
                @click="$set(type, 'content', '')"
                class="moderation__screen moderation__screen--mt"
                >Удалить текст условий</b-button
              >
            </div>
          </template>
          <p class="no-formats" v-else>Блогер не указал форматы рекламы</p>
        </div>
      </template>
      <template v-if="step === 8">
        <div class="settings__info--wrap">
          <h4 class="settings__title--small moderation__st">Город и юр. статус</h4>
          <div>
            <div class="mb-3">
              <p>Город блогера</p>
              <b-form-input v-model="blogger.current_city"></b-form-input>
            </div>
            <div v-if="legalStatusMapping[Number(blogger.legal_status)]" class="mb-3">
              <p>{{ legalStatusMapping[Number(blogger.legal_status)].name }}</p>
              <div class="d-flex flex-row">
                <b-form-input
                  v-model="blogger[legalStatusMapping[Number(blogger.legal_status)].model]"
                ></b-form-input>
                <b-button
                  class="ml-3"
                  v-clipboard:copy="blogger[legalStatusMapping[Number(blogger.legal_status)].model]"
                  variant="outline-default"
                  >Скопировать</b-button
                >
              </div>
            </div>
            <div class="moder__block--flex">
              <p>Блогер не рекламирует</p>
              <b-form-textarea
                style="min-height: 130px"
                v-model="blogger.excluded_tags"
              ></b-form-textarea>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="p-2" style="border-top: 1px solid lightgray; overflow-x: auto; white-space: nowrap">
      <b-button
        @click="prevStep"
        variant="outline-default"
        style="min-width: 20px; min-height: 33.5px"
        class="d-inline-block mx-2 my-2"
      >
        <svg class="moderation__arrow--icon mb-1 mr-1">
          <use xlink:href="../../assets/img/icons/sprite.svg#arrowleft"></use>
        </svg>
      </b-button>
      <b-button @click="showReasonModal = true" class="mx-2" variant="outline-default"
        >Отметить ошибки</b-button
      >
      <b-button class="ml-2" @click="nextStep" variant="primary">Далее</b-button>
      <b-button
        class="mx-2"
        v-if="step >= 8"
        @click="
          () => {
            blogger.moderation_status = 2;
            $emit('update', blogger.id, blogger);
          }
        "
        variant="yellow"
        >Одобрить</b-button
      >
      <b-button
        class="mr-2"
        v-if="step >= 8"
        @click="
          () => {
            blogger.moderation_status = 4;
            $emit('update', blogger.id, blogger);
          }
        "
        variant="outline-default"
        >На доработку</b-button
      >
      <b-button
        class="mr-2"
        v-if="step >= 8"
        @click="
          () => {
            blogger.moderation_status = 5;
            $emit('update', blogger.id, blogger);
          }
        "
        variant="outline-default"
        >Бан</b-button
      >
    </div>
    <Modal
      :show="showReasonModal"
      @hide="showReasonModal = false"
      title="Выберите ошибки"
      contentClass="p-4"
    >
      <b-form-checkbox-group
        size="lg"
        v-model="forRevisionModel"
        :options="forRevisionOptions"
        value-field="id"
        text-field="name"
        stacked
      ></b-form-checkbox-group
    ></Modal>
    <!--refuse-reason-modal
      :show="showReasonModal"
      :id="blogger.id"
      v-if="showReasonModal"
      @hide="showReasonModal = false"
      @input="customReasonHandler"
    /-->
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import bloggers from '@main/api/bloggers';
import Modal from '@main/components/reusable/modals/Modal.vue';
import moment from 'moment';
// import RefuseReasonModal from '@main/components/reusable/modals/RefuseReasonModal.vue';
import { mapState } from 'vuex';
import CitySelect from '../reusable/elements/selects/CitySelect.vue';

export default {
  components: {
    Multiselect,
    // RefuseReasonModal,
    CitySelect,
    Modal,
  },
  props: {
    blogger: Object,
    moderationSteps: Array,
  },
  data: () => ({
    step: 1,
    showReasonModal: false,
    legalStatusMapping: {
      2: {
        name: 'ИНН',
        model: 'inn',
      },
      3: {
        name: 'ОГРНИП',
        model: 'ogrnip',
      },
      4: {
        name: 'ОГРН',
        model: 'ogrn',
      },
    },
    refuseReasonsGroups: [],
  }),
  computed: {
    ...mapState(['bloggerOptions', 'countries', 'cities', 'tags']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    currentAudience() {
      return this.options.audiences.child.children.age_group.choices.filter(
        (val) => val.value === this.blogger.main_audience.age_group
      )[0];
    },
    dateCreated() {
      return moment(this.blogger.created_at).format('DD.MM.YYYY HH:mm');
    },
    forRevisionOptions() {
      if (!this.moderationSteps[this.step - 1]) {
        return;
      }
      return this.moderationSteps[this.step - 1].reasons;
    },
    forRevisionModel: {
      get() {
        return this.getActiveRefuseReasons();
      },
      set(newValue) {
        this.handleRefuseReasons(newValue);
      },
    },
  },
  methods: {
    nextStep() {
      if (this.step < 8) {
        this.step += 1;
      }
    },
    getKind(type) {
      try {
        return this.options.pr_types.child.children.kind.choices.filter(
          (val) => val.value === type.kind
        )[0].display_name;
      } catch (e) {
        return 'Неизвестно';
      }
    },
    getMonth(type) {
      try {
        return this.options.pr_types.child.children.month.choices.filter(
          (val) => val.value === type.month
        )[0].display_name;
      } catch (e) {
        return 'Неизвестно';
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    customReasonHandler(id, reason) {
      this.blogger.reject_reason = reason;
      this.blogger.moderation_status = 4;
      this.$emit('update', id, this.blogger);
    },
    handleRefuseReasons(value) {
      const idx = this.blogger.last_moderation_history_item.groups.findIndex(
        (val) => val.moderation_step === this.step
      );
      const group = { moderation_step: this.step, reasons: value };
      if (idx === -1) {
        this.blogger.last_moderation_history_item.groups.push(group);
        return;
      }
      this.blogger.last_moderation_history_item.groups[idx].reasons = value;
      console.log(this.blogger.last_moderation_history_item.groups);
    },
    getActiveRefuseReasons() {
      const group = this.blogger.last_moderation_history_item.groups.filter(
        (val) => val.moderation_step === this.step
      );
      if (group.length) {
        return group[0].reasons || [];
      }
      return [];
    },
  },
  watch: {
    step(val) {
      if (val === 1) {
        if (
          !this.blogger.coverage_min &&
          !this.blogger.coverage_max &&
          !this.blogger.coverage_screens.length
        ) {
          this.nextStep();
        }
      } else if (val === 2) {
        if (
          !this.blogger.coverage_posts_min &&
          !this.blogger.coverage_posts_max &&
          !this.blogger.posts_coverage_screens.length
        ) {
          this.nextStep();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn__wrap {
  display: flex;
}
.moderation__wrap {
  max-width: 50%;
  height: 520px;
  padding-bottom: 50px;
  display: flex;
  widows: 100%;
}
.mod_content-wrapper {
  overflow: auto;
}
.moderation__col {
  width: 100%;
}
.no-formats {
  margin-top: 30px;
}

.moder__buttons--right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 150px;
}
@media (max-width: 600px) {
  .mod-stat-card {
    min-height: 600px;
    width: 100%;
    min-width: unset !important;
  }
}
.mod-stat-card {
  min-height: 500px;
  min-width: 550px;
  margin-bottom: 30px;
}
</style>
